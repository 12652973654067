import type { EnvironmentConfig } from "./Config";

export const Config: EnvironmentConfig = {
  ROAD_WAVE_API_BASE_URL: "https://roadwavefm.api.linkedmink.net",
  USER_API_BASE_URL: "https://user.api.linkedmink.net",
  GOOGLE_MAPS_API_KEY: "",
  GOOGLE_OAUTH_CLIENT_ID: "",
  JWT_PUBLIC_KEY:
    "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1JSUNJakFOQmdrcWhraUc5dzBCQVFFRkFBT0NBZzhBTUlJQ0NnS0NBZ0VBd2pEYWM2amhUUjczalFndGJ5MGcNCkh5WURQYzZmLzVwdjFSbittT3F6WnhIVy93Q0E3VGlJc0JCeEppNHFKcDJveHVhRW9nUWp0blJlSE01Rnp2YTQNCllKQXR0TFpUZXRMajcycEhVWmlxbnd0WWJkRXNhSjgrS2c1TXV6SlRUQnVBTnBYWEJJdk83WkxyS1AreTl4NXINCmFNNkJUMWZBcWoxa2prYmdSN2p2cDA3RmtQQ3Z3MzBSaFc1Qjc5OW15OXJyTXpLRExVdzZ0a1dxS0JuY2d0ejMNCjhEVkJQRzZuVjdhRkk0bWdPaFZkcTl1SDVXU3YwZ3JqTm5nWHpYMmR1dzhKbWNaaUxFQzExVU5HQ3QzeFdva0wNCnpxM1pjMmpzVEE3RHlyUm5CZDdLTlJnbzJiNVhSb1ZHekRIOW1HdWQ4SVpSc2hBeWxSMFdwMzNicDhXNGlub1cNCnZlbytTZWFRWkZtSDdPQmk5R0E2ZzNRQ3pXRW5WdENDVmFTSTh5RmdtUGdjYjhSekx4bTEvVGR6TlY2WWJWRTQNCk8rVnJXRDJVdWZhMEtmYTRDSkVGYmRaM1NyWG13Vy91Q2N4RWMyNmM0R05oNnQ3VnJrMUhDd1lLWUJ4Qk83UjMNCkp6Z0NNN1BYam8rNk4xRDFIZVYxN1U1S1hZcVVTdVN3SFdaeWNESXR2V2JvclBWSW1jb0NZbS9DQ1NUNnJIVEYNCmdjdG52UldDMVIvTkdrUStucFZDc3AwcFhwM2RqTHhlUWt6QW5UZUZWL2VjaDQwdENndWowQUdoaFAycWs1L2YNCnUwSi93a2VhaDJiOU9ZL3M4Z014V2JrcFpFUnc5blBLUkZjellNYmhvNm9MUGhGeURHa2FmbHFGNDhFeTNPQ0kNCitNaW0rOUZxZTJIeEZCWnUyNDFPc2trQ0F3RUFBUT09DQotLS0tLUVORCBQVUJMSUMgS0VZLS0tLS0NCg==",
  ENABLE_SERVICE_WORKER: true,
  ENABLE_WEB_VITALS: false,
  DEBUG: false,
};
